import React, {PropsWithChildren, useEffect} from "react";
import {Dialog} from "primereact/dialog";
import styled from "styled-components";
import {ResponsiveProps, useBreakpoint} from "@Utils/responsive.utils";

const StyledDialog = styled(Dialog)<{width?: string} & ResponsiveProps>`
  padding: ${props => props.responsive.isDesktop ? "28px" : "14px"};
  width: ${props => props.responsive.isDesktop || props.responsive.small ? props.width : "93%"};
  height: ${props => (props.responsive.isDesktop || props.responsive.small) ? "max-content" : "min(100%, max-content)"};
  border-radius: 10px !important;
  background: #fff;
  .p-dialog-content {
    padding: 0 !important;
    border-radius: 0 0 0 0 !important;
  }
`;

export type CustomDialogProps = {
	visible: boolean;
	onHide: () => void;
	width?: string | number;
	zIndex?: number;
	closable?: boolean;
}
export const CustomDialog: React.FC<PropsWithChildren<CustomDialogProps>> = props => {

	const responsive = useBreakpoint();

	useEffect(() => {
		if ( document.fullscreenElement != null) {
			document.exitFullscreen();
		}
	}, [props.visible])

    return (
		<StyledDialog
			baseZIndex={props.zIndex}
			responsive={responsive}
			closable={props.closable}
			showHeader={false}
			visible={props.visible}
			onHide={props.onHide}
			width={typeof props.width === "number" ? `${props.width}px` : props.width}
			dismissableMask={props.closable}
		>
			{props.children}
		</StyledDialog>
    )
}
