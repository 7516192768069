import {useNavigate, useSearchParams} from "react-router-dom";
import {useFormik} from "formik";
import {confirmAccountSchema} from "@Schemas/auth.schema";
import {CustomInput} from "@Components/CustomInput";
import {Button} from "primereact/button";
import styled from "styled-components";
import {PasswordCheck} from "@Components/PasswordCheck";
import {useApi} from "@Hooks/api";
import {usePromise} from "@Hooks/promise";
import logo from "@Assets/images/logo-blue.svg";
import React from "react";
import {useBreakpoint} from "@Utils/responsive.utils";
import {classNames} from "primereact/utils";

export type RegisterFormProps = Record<string, never>
export const RegisterForm: React.FC<RegisterFormProps> = () => {

	const navigate = useNavigate();
	const [params] = useSearchParams();
	const api = useApi();

	const {phone, isTablet} = useBreakpoint();

	const [onRegister, loading] = usePromise(async (values: { password: string }) => {
		try {
			const code = params.get('code');
			if (code) {
				const result = await api.user_call_confirm({
					password: values.password,
					code
				});
				if (result.result !== "ok") throw new Error(result.result);
				navigate('/login');
			}
		} catch (e) {
			console.table(e);
			throw e;
		}
	}, {
		success: "Vous vous êtes correctement inscrit !",
		error: (err: Error) => err.message,
		pending: "Finalisation de votre inscription en cours..."
	});

	const formik = useFormik<{ password: string }>({
		initialValues: {
			password: "",
		},
		isInitialValid: false,
		validationSchema: confirmAccountSchema,
		onSubmit: onRegister
	});


	function onGoBack() {
		navigate('/login');
	}

	return (
		<div className={classNames(`Login flex h-full w-full flex-column align-items-center overflow-auto p-2`, (phone || isTablet) ? "justify-content-between bg-white" : "justify-content-center")}>
			<div className="my-4">
				<img src={logo} height={phone ? 35 : 50}/>
			</div>
			<FormWrapper className="bg-white">
				<div className="he-header--h1 primary-900 mb-3 text-center">
					Inscription
				</div>
				<div className="he-paragraph--regular gray-600 text-center mb-4">
					Terminez votre inscription en saisissant votre mot de passe ci-dessous, puis accédez à votre espace
					apprenant.
				</div>
				<CustomInput
					field={"password"}
					formik={formik}
					label={"Mot de passe"}
					placeholder={"Mot de passe"}
					type="password"
					validateOnInput
					showInlineError={false}
					className={"mb-2"}
				/>
				<PasswordCheck password={formik.values.password}/>
				<div className="mb-3 text-center">
					<span className="he-paragraph--small mb-2 gray-600">
						En m'inscrivant sur la plateforme, j’accepte les{" "}
						<a className="secondary-100 he-paragraph--small--underlined font-bold" target="_blank" referrerPolicy="no-referrer" href={"https://www.healthevents.fr/cgu"}>conditions générales d'utilisation</a> et {" "}
						<a className="secondary-100 he-paragraph--small--underlined font-bold" target="_blank" referrerPolicy="no-referrer" href={"https://www.healthevents.fr/rgpd"}>les modalités de traitement des données personnelles</a>{" "}
						et j'ai pris connaissance du{" "}
						<a className="secondary-100 he-paragraph--small--underlined font-bold" target="_blank" referrerPolicy="no-referrer" href={"https://www.healthevents.fr/reglement-interieur"}>règlement intérieur</a>.
					</span>
				</div>
				<Button
					className="mb-3 he-button--primary--md"
					label="Terminer mon inscription"
					onClick={() => formik.submitForm()}
					disabled={loading || !formik.isValid}
					loading={loading}/>

				<div className="text-center gray-500 he-paragraph--regular--underlined">
					<span className="cursor-pointer" onClick={onGoBack}>Vous avez déjà un compte ? Connectez-vous</span>
				</div>
			</FormWrapper>
			<div className="my-2 p-3 bg-yellow-10" style={{width: "100%", maxWidth: 450}}>
				<div className="he-paragraph--regular-16 gray-500 flex flex-column gap-2 align-items-center">
					<span className="yellow-100 uppercase he-paragraph--regular-16--bold">Besoin d’aide ?</span>
					<span className="yellow-100 text-center">Nous sommes disponibles <br /> du <span className={"font-bold"}>lundi</span> au <span className="font-bold">vendredi</span> de <span className={"font-bold"}>9h à 19h</span> au&nbsp;</span>
					<a href="tel:0176431249" className="yellow-100 no-underline" style={{fontSize: "16px", fontWeight: "bold"}}>
						<i className={"pi pi-phone mr-2"} style={{fontSize: 14}}/>01 76 43 12 49
					</a>
				</div>
			</div>
		</div>
	)
}

const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 450px;
  width: 100%;
  height: auto;
  padding: 28px;
`;
